import React from 'react';

import { BrowserRouter as Router, Route } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <Route path="/" exact component={Home} />
      <Route path="/pricing" component={Pricing} />
    </Router>
  );
}

function Home() {
  return <h1>Hello</h1>;
}

function Pricing() {
  return <h1>Pricing</h1>;
}

export default App;
